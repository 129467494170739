import szertartasrend from '../files/Szertartasrend.pdf';
import Card from 'react-bootstrap/Card';
import Iframe from 'react-iframe';

function Church(){
    return(
        <Card className="row">
            <div className="column img-container">
                <div style={{width:"90%", margin:"0 auto"}}>
                    <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3279.9584392881366!2d19.04049711863914!3d47.47889453323763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741ddb43f2c3b0d%3A0xa066895e3bb678f3!2sBudai%20Ciszterci%20Szent%20Imre%20Templom%20(historiz%C3%A1l%C3%B3%20neobarokk)!5e0!3m2!1shu!2shu!4v1717592150920!5m2!1shu!2shu" width="400" height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></Iframe>
                </div>
                

            </div>
            <div className="column">
                <div style={{width:"90%", padding:"0 auto"}}>
                    
                <h2><center>Nászmise</center></h2>
            <h3><center>Helyszín</center></h3>
            <p>A nászmise helyszíne a budai ciszterci Szent Imre templom <br />
            
                Cím: 1114 Budapest, Villányi út 25. <br /></p>
                <h3><center>Időpont</center></h3>
                <p>A nászmise 14 órakor kezdődik, <br />
                kérünk mindenkit, hogy 13.55-ig foglalja el helyét a templomban.</p>

                <h4>Parkolási és megközelítési lehetőségek: </h4>
                <p>Egy villamosmegállóra a Móricz Zsigmond körtértől 17-es vagy 61-es villamossal (Tas vezér utcai megálló)<br />
                27-es busszal (Tas vezér utcai megálló)
                </p>
                <p>Parkolás a templommal szemközti oldalon, illetve a környező utcákban lehetséges.</p>
                <p>A templom akadálymentes bejárata a templommal szemben állva a bal oldalon található.</p>
                <p>Mellékhelyiség a templom jobb oldalán a hátsó bejáraton keresztül elérhető el.</p>

                <a href={szertartasrend} target="_blank"rel="noreferrer" style={{"color":"black", "cursor":"pointer"}}>Szertartásrend PDF</a>

                <p style={{wordWrap:"break-word"}}>A nászmisés szertartás után mindenkit szeretettel várunk agapéra a templomkertben.</p>
                
                </div>
            </div>
        </Card>
    )
}

export default Church;