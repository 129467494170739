import {Outlet} from "react-router-dom";
import Slideshow from './Slideshow';
import MyLogo from './MyLogo';
import ResponsiveAppBar from './Menu';
import Footer from './Footer';
import Quote from "../Component/Quote";

function Layout(){
    return (
      <>
        <ResponsiveAppBar />
        <Quote />
        <Outlet />
        <Slideshow />
        <Footer />
      </>
    )
  }

  export default Layout;