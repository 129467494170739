import Typography from '@mui/material/Typography';

function HeaderTitleSmall(){
    return (
        <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2, display: { xs: 'flex', md: 'none' }, flexGrow: 1,
                fontFamily: 'monospace', fontWeight: 700,
                letterSpacing: '.3rem', color: 'white',
                textDecoration: 'none',
              }}
            >
              Lia és Tamás
            </Typography>
    )
}

export default HeaderTitleSmall;