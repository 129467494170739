

function Quote(){
    return (
        <div style={{backgroundColor:"#AA6C39", marginBottom:"15px", padding:"5px 0 5px 0", color:"#F1E5AC"}}>
            "Tutum iter unanimis" - "Az egyetértők útja biztos"
        </div>
    )
}

export default Quote;