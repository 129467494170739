import FormfacadeEmbed from "@formfacade/embed-react";

function ContactUs(){
    return(
        <div>
            <h2>Kapcsolat</h2>
            <p>Amennyiben kérdésed, észrevételed lenne számunkra, kérjük az alábbi űrlapon jelezd.</p>
            <FormfacadeEmbed
                formFacadeURL="https://formfacade.com/include/112254261363841370828/form/1FAIpQLSeEh1bMW_5fxZ03XMqfcr5BsapTVmeMJPUyxLQJ14uUB_85hw/classic.js/?div=ff-compose"
                onSubmitForm={() => console.log('Form submitted')}
            />
        </div>
    )
}

export default ContactUs;