import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const images = [
    'img/kep1.jpg',
    'img/kep2.jpg',
    'img/kep3.jpg'
  ];
  
  function Slideshow() {
    return (
      <div className="slide-container" style={{marginTop:"15px"}}>
        <Slide>
         {images.map((each, index)=> (
            <img key={index} style={{width:"100%"}} src={each} alt={index} />
            ))} 
        </Slide>
      </div>
    )
  }

  export default Slideshow;