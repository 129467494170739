
function Footer(){
    return(
        <div>
            <br />
            &#169; Hidvégi Tamás 2024
            <br />
            <br />
        </div>
    )
}

export default Footer;