import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

function MenuLarge({pages}){
    return (
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Link style={{ textDecoration: 'none' }} key={page.name} to={page.link}>
                  <Button
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    {page.name}
                  </Button>
                </Link>
                
              ))}
            </Box>
    )
}

export default MenuLarge;