import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
    {
      original: "img/gallery/kep01.JPG",
      originalHeight: 600,
    },
    {
      original: "img/gallery/kep02.JPG",
      originalHeight: 600,
    },
    {
      original: "img/gallery/kep03.jpg",
      originalHeight: 600,
    },
    {
        original: "img/gallery/kep04.jpg",
        originalHeight: 600,
      },
      {
        original: "img/gallery/kep05.jpeg",
        originalHeight: 600,
      },
      {
        original: "img/gallery/kep06.jpg",
        originalHeight: 600,
      },
      {
        original: "img/gallery/kep07.jpg",
        originalHeight: 600,
      },
      {
        original: "img/gallery/kep08.JPG",
        originalHeight: 600,
      },
      {
        original: "img/gallery/kep09.JPG",
        originalHeight: 600,
      },
      {
        original: "img/gallery/kep10.JPG",
        originalHeight: 600,
      },
      {
        original: "img/gallery/kep11.JPG",
        originalHeight: 600,
      },
      {
        original: "img/gallery/kep12.JPG",
        originalHeight: 600,
      },
      {
        original: "img/gallery/kep13.JPG",
        originalHeight: 600,
      },
      {
        original: "img/gallery/kep14.jpeg",
        originalHeight: 600,
      },
      {
        original: "img/gallery/kep15.jpeg",
        originalHeight: 600,
      },
  ];

function AboutOur(){
    return(
        <div>
            <div style={{"margin":"0 10%"}}>
            <p>
            Történetünk 2023 tavaszán kezdődött, Virágvasárnap. Egy közös kirándulás, majd mise után üzenetváltásokba kezdtünk, ami végül Lia 30. születésnapján, Húsvét hétfőn egy fantasztikus reggelit és egy vers-átiratot hozott ajándékba. Ismerkedni kezdtünk egymással, jöttek a rázós kérdések és végül május 17-ére datáltuk párkapcsolatunk kezdetét, ami egy szép taizéi imaórához és egy hosszú sétához kötődik. Sétálni jó - ezt azóta is tartjuk. 
</p><p>
A nyáron már családunk, barátaink előtt is vállaltuk kapcsolatunkat, melynek egyik helyszíne Tata volt. Innentől már nem volt megállás: séták, kirándulások, családi együttlétek Budapesten, Agárdon, Balatonon, a Mátrában, Kőszegen és Kaposváron.
</p><p>
Sétálni jó - ez volt az indok arra, hogy december 28-án a Duna part felé vetődtünk, ahol végül a Várkert bazárhoz jutottunk. Itt történt kapcsolatunk újabb állomása, a lánykérés. Ezt követően kezdtünk neki az esküvő szervezésének, a még rázósabb kérdéseknek, még több sétának. Azonban a hit, hogy minden rázódás és séta, mélység és magasság során tapasztaljuk, hogy a Jóisten mellettünk áll, minket is megerősít magunkban, egymásban. Ez az a hit és bizalom, amelyről tanúságot teszünk és amelyet életünk végéig vállalunk szeptember 21-én. 
            </p>
            </div>
            <ImageGallery items={images} showPlayButton={false}/>;
        </div>
    )
}

export default AboutOur;