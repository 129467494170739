import Typography from '@mui/material/Typography';

function HeaderTitleLarge(){
    return (
        <Typography
              variant="h6" noWrap component="a"
              href="/"
              sx={{
                mr: 2, display: { xs: 'none', md: 'flex' }, fontFamily: 'monospace',
                fontWeight: 700, letterSpacing: '.3rem', color: 'white',
                textDecoration: 'none',
              }}
            > Lia és Tamás </Typography>
    )
}

export default HeaderTitleLarge;